import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

const breadcrumbs = [
	<Link underline="hover" color="#7265b8" href="/">
		الرئيسية
	</Link>,
	<Link underline="hover" color="#7265b8" href="/khedma">
		الخدمة
	</Link>,
	<Typography key="3">تسجيل البيانات الشخصية</Typography>,
];

class RegisterOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	async onFormSubmit(e) {
		let { errors } = this.state;
		e.preventDefault();
		const name = e.target.name.value;
		const idnum = e.target.idnum.value;
		const phone = e.target.phone.value;
		// const iban = e.target.iban.value;
		errors = {};
		if (idnum.length < 5 || idnum.length > 10) {
			errors["idNumError"] = (
				<>
					<span>تأكد أن هذه القيمة تحتوي على 5 الى 10 حروف أو رمز على الأقل (هي تحتوي حالياً على {idnum.length}).</span>
					<br />
					<span>أدخل رقم بطاقة مدنيّة صحيح</span>
				</>
			);
		}
		// const regex = new RegExp(/^(?:\+?0*?965)?0?[0-9]{7}$/);
		// if (!regex.test(phone)) {
		// 	errors['phoneError'] = (
		// 		<>
		// 			<span>أدخل رقم هاتف صحيح</span>
		// 		</>
		// 	);
		// }
		if (Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			full_name: name,
			id_number: idnum,
			phone: phone,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/oman/insert-customer-details",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
		this.props.history.push("/register_two/" + idnum);
	}

	render() {
		const { loading, errors } = this.state;
		return (
			<div className="page-content register-one">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				{/* <div className="pagination_steps">
					<div className="pagination_step clicked">
						<p className="step-name">التفاصيل الشخصية</p>
						<p className="step-number">1</p>
					</div>
					<div className="vr" />
					<div className="pagination_step">
						<p className="step-name">التفاصيل البنكية</p>
						<p className="step-number">2</p>
					</div>
				</div> */}
				<form className="form-wrapper" onSubmit={this.onFormSubmit}>
					<div className="input-wrapper">
						<label className="input-label">الاسم الكامل:</label>
						<input className="form-input" name="name" type="text" autoComplete="off" required />
					</div>
					{errors.idNumError && <p className="error-message">{errors.idNumError}</p>}
					<div className="input-wrapper">
						<label className="input-label">الرقم المدني:</label>
						<input className="form-input" name="idnum" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					{errors.phoneError && <p className="error-message">{errors.phoneError}</p>}
					<div className="input-wrapper">
						<label className="input-label">رقم الهاتف:</label>
						<input className="form-input" name="phone" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					<div className="form-buttons">
						<button className="form-button" name="submitBtn">
							{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: "#fff" }} />}
							التالي
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default RegisterOne;
